
import { defineComponent } from "vue";

export default defineComponent({
  name: "file",
  props: {
    fileTitle: String,

    createdAt: String,

    fileType: String,
  },
  components: {},
});
