import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-stack mb-6" }
const _hoisted_2 = { class: "d-flex my-2" }
const _hoisted_3 = { class: "d-flex align-items-center position-relative me-4" }
const _hoisted_4 = { class: "svg-icon svg-icon-3 position-absolute ms-3" }
const _hoisted_5 = { class: "row g-6 g-xl-9 mb-6 mb-xl-9" }
const _hoisted_6 = { class: "row g-6 g-xl-9 mb-6 mb-xl-9" }
const _hoisted_7 = { class: "row g-6 g-xl-9 mb-6 mb-xl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTFolder = _resolveComponent("KTFolder")!
  const _component_KTFile = _resolveComponent("KTFile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fw-bolder my-2" }, [
        _createTextVNode(" My Documents "),
        _createElementVNode("span", { class: "fs-6 text-gray-400 fw-bold ms-1" }, "100+ resources")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("input", {
            type: "text",
            id: "kt_filter_search",
            class: "form-control form-control-white form-control-sm w-150px ps-9",
            placeholder: "Search"
          }, null, -1))
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("a", {
          href: "#",
          class: "btn btn-primary btn-sm",
          "data-bs-toggle": "tooltip",
          title: "Coming soon"
        }, " File Manager ", -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_KTFolder, {
        "folder-name": "Finance",
        files: "7"
      }),
      _createVNode(_component_KTFolder, {
        "folder-name": "Customers",
        files: "3"
      }),
      _createVNode(_component_KTFolder, {
        "folder-name": "CRM Project",
        files: "25"
      }),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col d-none d-xl-block" }, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col d-none d-xl-block" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_KTFile, {
        "file-title": "Project Reqs..",
        "file-type": "pdf",
        "created-at": "3 days ago"
      }),
      _createVNode(_component_KTFile, {
        "file-title": "CRM App Docs..",
        "file-type": "doc",
        "created-at": "3 days ago"
      }),
      _createVNode(_component_KTFile, {
        "file-title": "User CRUD Styles",
        "file-type": "css",
        "created-at": "4 days ago"
      }),
      _createVNode(_component_KTFile, {
        "file-title": "Metronic Logo",
        "file-type": "ai",
        "created-at": "5 days ago"
      }),
      _createVNode(_component_KTFile, {
        "file-title": "Orders backup",
        "file-type": "sql",
        "created-at": "1 week ago"
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_KTFile, {
        "file-title": "UTAIR CRM API Co..",
        "file-type": "xml",
        "created-at": "2 weeks ago"
      }),
      _createVNode(_component_KTFile, {
        "file-title": "Tower Hill App..",
        "file-type": "tif",
        "created-at": "3 weeks ago"
      }),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col d-none d-xl-block" }, null, -1)),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col d-none d-xl-block" }, null, -1)),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col d-none d-xl-block" }, null, -1))
    ])
  ], 64))
}